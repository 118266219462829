<template>
  <div class="outer-page">
    <div class="sub-section warrper">
      <span class="prev run-btn" @click="prevBtn"></span>
      <span class="next run-btn" @click="nextBtn"></span>
      <div class="person-info">
        <ul :style="{ width: ulWidth + 'px', left: ulLeft + 'px' }">
          <li
            v-for="(item, index) in personList"
            @click="goThere(item)"
          >
            <a>
              <img :src="item.servicePath + item.cover" alt="" />
              <h6>{{ item.name }}</h6>
              <p></p>
              <p>
                <span style="font-family: Microsoft YaHei; font-size: 14px">{{
                  briefFirst[index]
                }}</span>
              </p>
              <p>
                <span style="font-family: Microsoft YaHei; font-size: 14px">{{
                  briefLast[index]
                }}</span>
              </p>
              <p></p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      ulWidth: 4000,
      ulLeft: 0,
      personList: [],
      briefFirst: [],
      briefLast: [],
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    prevBtn() {
      this.ulLeft += 266;
      if (this.ulLeft >= 0) {
        this.ulLeft = 0;
      }
    },
    nextBtn() {
      this.ulLeft -= 266;
      if (this.ulLeft <= (this.personList.length - 4) * -266) {
        this.ulLeft = (this.personList.length - 4) * -266;
      }
    },
    goThere(item) {
      this.$router.push({
        name: "newsInfo",
        query: {
          id: item.id,
          key: this.$route.name
        },
      });
    },
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 999,
          pageNo: 1,
        }).then((res) => {
          this.personList = res.list;
          for (let i = 0; i < this.personList.length; i++) {
            this.briefFirst.push(this.personList[i].brief.split("/")[0]);
            this.briefLast.push(this.personList[i].brief.split("/")[1]);
          }
          this.ulWidth = res.list.length * 270;
        });
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>